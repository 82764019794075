import Vue from 'vue';
import VueRouter from "vue-router";
import SessionManager from "@/utils/session";
import LoginPage from "@/views/LoginPage";
import HomePage from "@/views/HomePage";
import BindingManager from "@/utils/binding";
import {Environment} from "@/utils/environment";

Vue.use(VueRouter);

const router = new VueRouter({
    routes: [
        {
            path: '/:vendor/login',
            name: 'login',
            component: LoginPage,
        },
        {
            path: '/:vendor/',
            name: 'home',
            component: HomePage,
        },
        {
            path: '*', redirect: {name: 'home'}
        },
    ],
    mode: 'history',
});

export default router;

router.beforeEach(async (to, from, next) => {

    // e-KTP route
    let isEKTP = to.path.includes("ektp");

    if (isEKTP) {
        Environment.VENDOR_IS_EKTP = isEKTP
    }

    // Authorization route
    if (to.path === "/authorize/") {
        let otp = to.query["otp"];

        BindingManager.acknowledge(otp)
            .then(() => {
                router.push("/", () => {});
            })
            .catch(() => {
                router.push("/", () => {});
            });
        // Return since we should wait for the handling of the authorization request
        return;
    }

    let authenticated;

    try {
        authenticated = await SessionManager.isAuthenticated();
    } catch (e) {
        switch (e.message) {
            case SessionManager.ERRORS.X_CSRF_TOKEN_INVALID:
                await BindingManager.start(isEKTP);
                break;
            default:
                console.error(e);
        }
        return;
    }

    let vendorID = to.params.vendor || "ubq";

    // Authentication header guard
    if (to.name !== "login" && !authenticated) {
        next({ name: "login", params: {vendor: vendorID} })
    }

    if (to.name === "login" && authenticated) {
        next({ name: "home", params: {vendor: vendorID} })
    } else {
        next()
    }

});