import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":"","flat":""}},[_c(VToolbarTitle,{staticClass:"font-weight-medium"},[_c(VIcon,[_vm._v("mdi-cellphone-sound")]),_vm._v(" Decrypt with Authenticate ")],1),_c(VSpacer),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""},on:{"click":_vm.closeDialog}},on),[_c(VIcon,[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Close Dialog")])])],1),_c(VContainer,{staticClass:"px-4"},[_c('p',{staticClass:"title text--primary"},[_vm._v("Decrypt document with app")]),_c('p',{},[_vm._v(" Open the push message you received on your phone. Click on the message you just received and confirm this with your PIN ")]),_c(VAlert,{attrs:{"type":"info","dense":"","text":"","color":"primary"}},[_vm._v(" Didn't receive a push message? Open the app and refresh your screen. ")]),_c('p',[_vm._v("Document that will be decrypted:")]),_c(VChip,{staticClass:"mb-4 font-weight-medium",attrs:{"color":"primary","label":"","outlined":""}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-file-document")]),_vm._v(" banking_agreement.pdf ")],1),_c(VProgressLinear,{attrs:{"indeterminate":"","color":"primary"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }