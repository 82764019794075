const envs = {
    LOCAL: 'lcl',
    STAGING: 'stg',
    SANDBOX: 'sbx',
    PROD: 'prd',
}

let env;

/**
 * Contains all environment variables
 */
export const Environment = {

    get API_BASE_URL() {
        switch (env) {
            case envs.LOCAL:
                // return "http://localhost:8113/";
                return "https://api.demo.ubiqu-staging.com/";
            case envs.STAGING:
                return "https://api.demo.ubiqu-staging.com/";
            case envs.SANDBOX:
                return "https://api.demo.ubiqu-sandbox.com/";
            case envs.PROD:
                return "https://api.demo.ubiqu.com/";
            default:
                return "";
        }
    },

    get APPLICATION_TITLE() {
        if (this.IS_EKTP) {
            return "Demo pendaftaran online";
        }

        if (env === envs.PROD) {
            return "Ubiqu Demo";
        } else {
            return "Ubiqu Demo - " + Environment.BACKEND_ENV.toUpperCase();
        }
    },

    get BACKEND_ENV() {
        return env;
    },

    VENDOR_IS_EKTP: false,

    get IS_EKTP() {
        return this.VENDOR_IS_EKTP && env === envs.STAGING;
    },

    get IDP() {
        switch (env) {
            case envs.LOCAL:
            case envs.STAGING:
                return "cf0609e7538e44928181a16a7eef6dc0";
            case envs.SANDBOX:
                return "125b039fdad1448dbb3fd6853457d39a";
            case envs.PROD:
                return "0668d7428a68467c8e84be84fcccdc87";
            default:
                return "";
        }
    },

}

// All down here is to detect the environment
let hostname = window.location.hostname;

if (hostname === "localhost") {
    env = envs.LOCAL;
} else {
    let envInHostname = hostname.split(".")[1].replace("ubiqu", "");

    switch (envInHostname) {
        case "-staging":
            env = envs.STAGING;
            break;
        case "-sandbox":
            env = envs.SANDBOX;
            break;
        case "": //prd
            env = envs.PROD;
            break
    }

}