import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSlideYTransition,[_c(VContainer,{directives:[{name:"show",rawName:"v-show",value:(_vm.loaded),expression:"loaded"}],staticClass:"pa-0 pa-sm-2"},[(_vm.isEKTP)?[_c(VRow,{staticClass:"mt-16 d-sm-flex d-none",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"mx-auto",attrs:{"cols":"7"}},[_c('medical-data')],1)],1)]:[_c(VRow,{staticClass:"mt-16 d-sm-flex d-none",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"sm":"6"}},[_c('sign-document'),_c('manage-certificates',{staticClass:"mt-8"})],1),_c(VCol,{attrs:{"sm":"6"}},[_c('bank-account')],1)],1),_c(VRow,{staticClass:"mt-0 d-sm-none d-block",staticStyle:{"height":"100%"},attrs:{"no-gutters":""}},[(_vm.componentChosen === 0)?_c(VCol,{staticStyle:{"height":"100%"},attrs:{"cols":"12"}},[_c('sign-document')],1):_vm._e(),(_vm.componentChosen === 1)?_c(VCol,{staticStyle:{"height":"100%"},attrs:{"cols":"12"}},[_c('bank-account')],1):_vm._e(),_c(VBottomNavigation,{staticClass:"d-flex d-sm-none",attrs:{"app":"","background-color":"primary","dark":""},model:{value:(_vm.componentChosen),callback:function ($$v) {_vm.componentChosen=$$v},expression:"componentChosen"}},[_c(VBtn,[_c('span',[_vm._v("Documents")]),_c(VIcon,[_vm._v("mdi-file")])],1),_c(VBtn,[_c('span',[_vm._v("Banking")]),_c(VIcon,[_vm._v("mdi-bank")])],1)],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }