import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"mx-sm-4 mx-0"},[_c(VToolbar,{staticClass:"d-none d-sm-flex",attrs:{"flat":"","color":"primary","dark":""}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-file-document-edit")]),_c(VToolbarTitle,{staticClass:"title"},[_vm._v("Documents")])],1),_c(VContainer,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"mt-2 mx-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"subtitle-1 font-weight-medium"},[_vm._v("Secured documents")]),_c('p',[_vm._v("The following document is stored encrypted:")]),_c(VChip,{staticClass:"mb-4 font-weight-medium",attrs:{"color":"primary","label":"","outlined":""}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-file-document")]),_vm._v(" banking_agreement.pdf ")],1),_c('p',[_vm._v("In order to view the document you need to decrypt it.")])],1)],1),_c(VRow,{staticClass:"mx-2 mb-2",attrs:{"no-gutters":""}},[_c(VSpacer),_c(VBtn,{staticClass:"white--text",attrs:{"color":"primary","shaped":""},on:{"click":_vm.decryptDocument}},[_vm._v("Decrypt ")])],1)],1),(_vm.decryptingDocument)?_c('decrypt-file-dialog',{on:{"close":function($event){_vm.decryptingDocument = false}}}):_vm._e(),_c(VFadeTransition,[(hover)?_c(VOverlay,{attrs:{"absolute":"","opacity":0.7}},[_c('kbd',{staticClass:"primary"},[_vm._v("This feature is not ready yet!")])]):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }