<template>

    <v-hover>
        <template v-slot:default="{ hover }">
            <v-card class="mx-sm-4 mx-0">

                <v-toolbar flat color="primary" dark class="d-none d-sm-flex">
                    <v-icon class="mr-2">mdi-file-document-edit</v-icon>
                    <v-toolbar-title class="title">Documents</v-toolbar-title>
                </v-toolbar>

                <v-container>

                    <v-row no-gutters>

                        <v-col cols="12" class="mt-2 mx-2">

                            <div class="subtitle-1 font-weight-medium">Secured documents</div>

                            <p>The following document is stored encrypted:</p>

                            <v-chip
                                class="mb-4 font-weight-medium"
                                color="primary"
                                label
                                outlined
                            >
                                <v-icon left>mdi-file-document</v-icon>
                                banking_agreement.pdf
                            </v-chip>

                            <p>In order to view the document you need to decrypt it.</p>

                        </v-col>

                    </v-row>

                    <v-row no-gutters class="mx-2 mb-2">
                        <v-spacer></v-spacer>
                        <v-btn
                            class="white--text"
                            color="primary"
                            shaped
                            @click="decryptDocument"
                        >Decrypt
                        </v-btn>
                    </v-row>

                </v-container>

                <decrypt-file-dialog
                    v-if="decryptingDocument"
                    @close="decryptingDocument = false"
                ></decrypt-file-dialog>

                <v-fade-transition>
                    <v-overlay
                        v-if="hover"
                        absolute
                        :opacity="0.7"
                    >
                        <kbd class="primary">This feature is not ready yet!</kbd>
                    </v-overlay>
                </v-fade-transition>

            </v-card>
        </template>
    </v-hover>

</template>

<script>
import DecryptFileDialog from "@/components/DecryptFileDialog";

export default {
    name: "SignDocument",

    components: {
        DecryptFileDialog
    },

    data: () => ({
        decryptingDocument: false,
    }),

    methods: {

        decryptDocument() {
            this.decryptingDocument = true;
        },

    },

}
</script>

<style scoped>

</style>