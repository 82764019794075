<template>

    <v-dialog
        v-model="modal"
        persistent
        max-width="500"
    >

        <v-card>

            <v-toolbar color="primary" dark flat>

                <v-toolbar-title class="font-weight-medium">
                    <v-icon>mdi-cellphone-sound</v-icon>
                    Sign with Authenticate
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="closeDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Close Dialog</span>
                </v-tooltip>

            </v-toolbar>

            <v-container class="px-4">

                <p class="title text--primary">Sign transfer with app</p>

                <template v-if="transactionStatus === 0">
                    <p class="">
                        Open the push message you received on your phone.
                        Click on the message you just received and confirm this with your PIN
                    </p>

                    <v-alert type="info" dense text color="primary">
                        Didn't receive a push message? Open the app and refresh your screen.
                    </v-alert>
                </template>

                <v-list two-line class="pt-0 mt-n4" subheader>
                    <v-subheader class="font-weight-medium px-0">Transfer Details</v-subheader>
                    <v-list-item class="px-0">
                        <v-list-item-avatar>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>{{ receiver }}</v-list-item-title>
                            <v-list-item-subtitle>Receiver</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="px-0">
                        <v-list-item-avatar>
                            <v-icon>mdi-numeric</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>{{ accountNumber }}</v-list-item-title>
                            <v-list-item-subtitle>Account Number</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="px-0">
                        <v-list-item-avatar>
                            <v-icon>mdi-currency-eur</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>{{ amount }} EUR</v-list-item-title>
                            <v-list-item-subtitle>Amount</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                </v-list>

                <v-progress-linear
                    v-if="transactionStatus === 0"
                    indeterminate
                    color="primary"
                ></v-progress-linear>

                <v-alert v-if="transactionStatus === 3" type="success" dense text>
                    Transaction succeeded!
                    <template v-slot:append>
                        <v-btn text class="my-n2" color="green" @click="closeDialog">
                            Finish
                        </v-btn>
                    </template>
                </v-alert>

                <v-alert v-if="transactionStatus === 5" type="error" dense text>
                    You rejected the transaction.
                    <template v-slot:append>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" color="red" icon small @click="restartTransaction">
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Try again</span>
                        </v-tooltip>
                    </template>
                </v-alert>

                <v-alert v-if="transactionStatus === 6" type="error" dense text>
                    The transaction expired.
                    <template v-slot:append>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" color="red" icon small @click="restartTransaction">
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Try again</span>
                        </v-tooltip>
                    </template>
                </v-alert>

            </v-container>

        </v-card>



    </v-dialog>

</template>

<script>
export default {
    name: "TransferMoneyDialog",

    props: {

        accountNumber: {
            type: String,
            required: true,
        },

        amount: {
            type: Number,
            required: true
        },

        receiver: {
            type: String,
            required: true,
        },

        transactionStatus: {
            type: Number,
            required: true
        },

    },

    data: () => ({
        modal: true
    }),

    methods: {

        closeDialog() {
            this.$emit("close");
        },

        restartTransaction() {
            this.$emit("restart");
        }

    },

}
</script>

<style scoped>

</style>