<template>

    <v-app>

        <v-app-bar
            app
            clipped-left
            color="primary"
            dark
        >

            <v-img
                v-if="isEKTP"
                src="./assets/ektp/ektp-logo.png"
                style="background-color: white; border-radius: 5px"
                max-height="30"
                max-width="30"
                contain
                class="mr-4"
            ></v-img>

            <v-img
                v-if="!isEKTP"
                src="./assets/ubiqu_white.svg"
                max-height="30"
                max-width="30"
                contain
                class="mr-4"
            ></v-img>

            <v-toolbar-title class="text-h6">
                {{ applicationTitle }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn v-if="!isLoginView" text class="white--text" @click="onLogoutClicked">{{ $t('logout') }}</v-btn>

        </v-app-bar>

        <v-main style="background-color: #eeeeee">
            <router-view style="height: 100%"></router-view>
        </v-main>

    </v-app>

</template>

<script>

import API from "@/utils/api";
import SessionManager from "@/utils/session";
import {Environment} from "@/utils/environment";

export default {
    name: "App",

    data: () => ({
        applicationTitle: "",
    }),

    computed: {

        isEKTP() {
            return Environment.IS_EKTP;
        },

        isLoginView() {
            return this.$route.name === "login";
        }

    },

    methods: {

        async onLogoutClicked() {
            await SessionManager.logout();
            await this.$router.push({ name: "login" });
        },

    },

    created() {
        API.init(() => {
            this.$router.push({ name: "login" });
        })

        this.applicationTitle = Environment.APPLICATION_TITLE;
        this.$root.$i18n.locale = Environment.IS_EKTP ? "in" : "en";
    }

}
</script>

<style scoped>

</style>

<i18n>
{
    "en": {
        "logout": "Logout"
    },
    "in": {
        "logout": "Keluar"
    }
}
</i18n>