<template>

    <v-container height="100%" fluid class="pb-0 grey lighten-3">

        <v-row justify="center" class="grey lighten-3">

            <v-fade-transition>

                <v-card v-show="loaded" elevation="12" width="600" class="mt-sm-16">

                    <v-row no-gutters>

                        <v-col cols="auto">
                            <!-- Tabs -->
                            <v-tabs v-model="registerSelection">
                                <v-tabs-slider color="primary"></v-tabs-slider>
                                <v-tab>{{ $t('login') }}</v-tab>
                                <v-tab>{{ $t('register') }}</v-tab>
                            </v-tabs>
                        </v-col>

                        <v-col cols="4" class="ml-auto" v-if="isEKTP">
                            <v-img
                                src="../assets/ektp/ektp-medical.png"
                                height="100%"
                                contain
                            ></v-img>
                        </v-col>

                    </v-row>

                    <v-divider></v-divider>

                    <v-card-text class="py-0 mt-2">

                        <v-scroll-x-reverse-transition hide-on-leave>
                        <template v-if="registerSelection === 0">
                            <login-view></login-view>
                        </template>
                        <template v-else>
                            <register-view></register-view>
                        </template>
                        </v-scroll-x-reverse-transition>

                    </v-card-text>

                </v-card>

            </v-fade-transition>

        </v-row>

    </v-container>

</template>

<script>
import RegisterView from "@/views/login/RegisterView";
import LoginView from "@/views/login/LoginView";
import {Environment} from "../utils/environment";

export default {
    name: "LoginPage",

    components: {
        LoginView,
        RegisterView,
    },

    data: () => ({
        loaded: false,
        registerSelection: 0,
    }),

    computed: {

        isEKTP() {
            return Environment.IS_EKTP;
        },

    },

    methods: {

    },

    created() {

    },

    mounted() {
        setTimeout(() => {
            this.loaded = true;
        }, 500);
    },

}
</script>

<style scoped>

</style>

<i18n>
{
    "en": {
        "login": "Login",
        "register": "Register"
    },
    "in": {
        "login": "Masuk",
        "register": "Daftar"
    }
}
</i18n>