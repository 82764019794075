<template>

    <v-card class="mx-sm-4 mx-0">

        <v-toolbar flat color="primary" dark class="d-none d-sm-flex">
            <v-icon class="mr-2" color="grey lighten-2">mdi-hospital-building</v-icon>
            <v-toolbar-title class="text-h6">
                Rumah Sakit Medistra
            </v-toolbar-title>
        </v-toolbar>

        <v-container>

            <v-list two-line class="pt-0">

                <v-list-item class="my-n2">
                    <v-list-item-content>
                        <v-list-item-title>Petugas pendaftaran</v-list-item-title>
                        <v-list-item-subtitle>Hari Rabu - 30 juni 2021</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item class="my-n2">
                    <v-list-item-content>
                        <v-list-item-title>3275120605840001</v-list-item-title>
                        <v-list-item-subtitle>N.I.K.</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-row>

                    <!-- Name etc. -->
                    <v-col>

                        <v-list-item class="my-n2 px-0">
                            <v-list-item-avatar>
                                <v-icon>mdi-account</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ username }}</v-list-item-title>
                                <v-list-item-subtitle>Nama</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item class="my-n2 px-0">
                            <v-list-item-avatar/>

                            <v-list-item-content>
                                <v-list-item-title>JAKARTA, 06-05-1984</v-list-item-title>
                                <v-list-item-subtitle>Tempat/Tgl Lahir</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item class="my-n2 px-0">
                            <v-list-item-avatar/>

                            <v-list-item-content>
                                <v-list-item-title>LAKI-LAKI</v-list-item-title>
                                <v-list-item-subtitle>Jenis Kelamin</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item class="my-n2 px-0">
                            <v-list-item-avatar/>

                            <v-list-item-content>
                                <v-list-item-title>O</v-list-item-title>
                                <v-list-item-subtitle>Gol. Darah</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item class="my-n2 px-0">
                            <v-list-item-avatar/>

                            <v-list-item-content>
                                <v-list-item-title>ISLAM</v-list-item-title>
                                <v-list-item-subtitle>Agama</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item class="my-n2 px-0">
                            <v-list-item-avatar/>

                            <v-list-item-content>
                                <v-list-item-title>KAWIN</v-list-item-title>
                                <v-list-item-subtitle>Status Perkawinan</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item class="my-n2 px-0">
                            <v-list-item-avatar/>

                            <v-list-item-content>
                                <v-list-item-title>KARYAWAN BUMN</v-list-item-title>
                                <v-list-item-subtitle>Pekerjaan</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item class="my-n2 px-0">
                            <v-list-item-avatar/>

                            <v-list-item-content>
                                <v-list-item-title>WNI</v-list-item-title>
                                <v-list-item-subtitle>Kewarganegaraan</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                    </v-col>

                    <!-- Address etc. -->
                    <v-col class="px-0">

                        <v-list-item class="my-n2 px-0">
                            <v-list-item-avatar>
                                <v-icon>mdi-map-marker</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>KP. RAWA BACANG NO. 8</v-list-item-title>
                                <v-list-item-subtitle>Alamat</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item class="my-n2 px-0">
                            <v-list-item-avatar/>

                            <v-list-item-content>
                                <v-list-item-title>007/08</v-list-item-title>
                                <v-list-item-subtitle>RT/RW</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item class="my-n2 px-0">
                            <v-list-item-avatar/>

                            <v-list-item-content>
                                <v-list-item-title>JATIRAHAYU</v-list-item-title>
                                <v-list-item-subtitle>Kelurahan</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item class="my-n2 px-0">
                            <v-list-item-avatar/>

                            <v-list-item-content>
                                <v-list-item-title>PONDOK MELATI</v-list-item-title>
                                <v-list-item-subtitle>Kecamatan</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                    </v-col>

                </v-row>

                <v-divider></v-divider>

                <v-list-item class="my-n2 px-0">
                    <v-list-item-avatar/>

                    <v-list-item-content>
                        <v-list-item-title>06-05-2016</v-list-item-title>
                        <v-list-item-subtitle>Berlaku Hingga</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

            </v-list>

        </v-container>

    </v-card>

</template>

<script>
import SessionManager from "@/utils/session";

export default {
    name: "MedicalData",

    data: () => ({
        username: "",
    }),

    created() {
        this.username = SessionManager.username;
    }

}
</script>

<style scoped>

</style>