import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VWindow,{attrs:{"touchless":true},model:{value:(_vm.loginWindow),callback:function ($$v) {_vm.loginWindow=$$v},expression:"loginWindow"}},[_c(VWindowItem,{attrs:{"value":0}},[_c('identify-view',{attrs:{"identify-object":_vm.identifyObject,"app-api":_vm.appApi,"header":_vm.$t('login_header')}})],1),_c(VWindowItem,{attrs:{"value":2}},[_c(VContainer,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c('p',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.$t('confirm_header')))]),_c('p',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.$t('welcome'))+" "+_vm._s(_vm.username))]),_c('p',[_vm._v(" 1. "+_vm._s(_vm.$t('step1'))+" ")]),_c('p',[_vm._v(" 2. "+_vm._s(_vm.$t('step2'))+" ")]),(!_vm.rejected)?_c(VAlert,{attrs:{"type":"success","dense":""}},[_vm._v(_vm._s(_vm.$t('success')))]):_vm._e(),(_vm.rejected)?_c(VAlert,{attrs:{"type":"error","dense":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":"","small":""},on:{"click":_vm.restartLogin}},on),[_c(VIcon,[_vm._v("mdi-refresh")])],1)]}}],null,false,2087020706)},[_c('span',[_vm._v(_vm._s(_vm.$t('try_again')))])])]},proxy:true}],null,false,1977868911)},[_vm._v(" "+_vm._s(_vm.$t('error'))+" ")]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }