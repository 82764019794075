<template>

    <v-slide-y-transition>

        <v-container class="pa-0 pa-sm-2" v-show="loaded">

            <template v-if="isEKTP">

                <v-row no-gutters class="mt-16 d-sm-flex d-none">
                    <v-col cols="7" class="mx-auto">
                        <medical-data/>
                    </v-col>
                </v-row>

            </template>

            <template v-else>

                <v-row no-gutters class="mt-16 d-sm-flex d-none">

                    <!-- Sign Document -->
                    <v-col sm="6">
                        <sign-document/>
                        <manage-certificates class="mt-8"></manage-certificates>
                    </v-col>

                    <!-- Transfer Money -->
                    <v-col sm="6">
                        <bank-account/>
                    </v-col>

                </v-row>

                <v-row no-gutters class="mt-0 d-sm-none d-block" style="height: 100%">

                    <!-- Sign Document -->
                    <v-col cols="12" v-if="componentChosen === 0" style="height: 100%">
                        <sign-document></sign-document>
                    </v-col>

                    <!-- Transfer Money -->
                    <v-col cols="12" v-if="componentChosen === 1" style="height: 100%">
                        <bank-account></bank-account>
                    </v-col>

                    <v-bottom-navigation
                        v-model="componentChosen"
                        app
                        background-color="primary"
                        class="d-flex d-sm-none"
                        dark
                    >
                        <v-btn>
                            <span>Documents</span>
                            <v-icon>mdi-file</v-icon>
                        </v-btn>

                        <v-btn>
                            <span>Banking</span>
                            <v-icon>mdi-bank</v-icon>
                        </v-btn>

                    </v-bottom-navigation>

                </v-row>

            </template>


        </v-container>

    </v-slide-y-transition>


</template>

<script>
import BankAccount from "@/views/home/BankAccount";
import SignDocument from "@/views/home/SignDocument";
import ManageCertificates from "@/views/home/ManageCertificates";
import {Environment} from "../utils/environment";
import MedicalData from "./home/MedicalData";

export default {
    name: "HomePage",

    components: {
        MedicalData,
        ManageCertificates,
        SignDocument,
        BankAccount
    },

    data: () => ({
        componentChosen: 0,
        loaded: false,
    }),

    computed: {

        isEKTP() {
            return Environment.IS_EKTP;
        }

    },

    mounted() {
        setTimeout(() => {
            this.loaded = true;
        }, 500);
    },

}
</script>

<style scoped>

</style>