import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScrollXReverseTransition } from 'vuetify/lib/components/transitions';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pb-0 grey lighten-3",attrs:{"height":"100%","fluid":""}},[_c(VRow,{staticClass:"grey lighten-3",attrs:{"justify":"center"}},[_c(VFadeTransition,[_c(VCard,{directives:[{name:"show",rawName:"v-show",value:(_vm.loaded),expression:"loaded"}],staticClass:"mt-sm-16",attrs:{"elevation":"12","width":"600"}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VTabs,{model:{value:(_vm.registerSelection),callback:function ($$v) {_vm.registerSelection=$$v},expression:"registerSelection"}},[_c(VTabsSlider,{attrs:{"color":"primary"}}),_c(VTab,[_vm._v(_vm._s(_vm.$t('login')))]),_c(VTab,[_vm._v(_vm._s(_vm.$t('register')))])],1)],1),(_vm.isEKTP)?_c(VCol,{staticClass:"ml-auto",attrs:{"cols":"4"}},[_c(VImg,{attrs:{"src":require("../assets/ektp/ektp-medical.png"),"height":"100%","contain":""}})],1):_vm._e()],1),_c(VDivider),_c(VCardText,{staticClass:"py-0 mt-2"},[_c(VScrollXReverseTransition,{attrs:{"hide-on-leave":""}},[(_vm.registerSelection === 0)?[_c('login-view')]:[_c('register-view')]],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }