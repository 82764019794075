import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-sm-4 mx-0"},[_c(VToolbar,{staticClass:"d-none d-sm-flex",attrs:{"flat":"","color":"primary","dark":""}},[_c(VIcon,{staticClass:"mr-2 mt-1"},[_vm._v("mdi-certificate")]),_c(VToolbarTitle,{staticClass:"title"},[_vm._v("Certificates")])],1),_c(VContainer,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"mt-2 mx-2",attrs:{"cols":"12"}},[_c('p',[_vm._v("Sign a CSR using the Authenticate app.")]),_c(VForm,{ref:"transferDetails"},[_c(VListItem,[_c(VTextField,{attrs:{"disabled":_vm.creatingCSR,"label":"Common Name (CN)","rules":_vm.requiredRules},model:{value:(_vm.commonName),callback:function ($$v) {_vm.commonName=$$v},expression:"commonName"}})],1),_c(VListItem,[_c(VTextField,{attrs:{"disabled":_vm.creatingCSR,"label":"Organization","rules":_vm.requiredRules},model:{value:(_vm.organization),callback:function ($$v) {_vm.organization=$$v},expression:"organization"}})],1)],1)],1)],1),_c(VRow,{staticClass:"mx-2 mb-2",attrs:{"no-gutters":""}},[_c(VSpacer),_c(VBtn,{staticClass:"white--text",attrs:{"color":"primary","shaped":"","disabled":_vm.creatingCSR},on:{"click":_vm.signCSR}},[_c(VProgressCircular,{directives:[{name:"show",rawName:"v-show",value:(_vm.creatingCSR),expression:"creatingCSR"}],staticClass:"mr-1",attrs:{"size":"20","indeterminate":"","color":"primary"}}),_vm._v(" Sign CSR ")],1)],1)],1),(_vm.csrOngoing)?_c('sign-csr-dialog',{attrs:{"common-name":_vm.commonName,"organization":_vm.organization,"transaction-status":_vm.transactionStatus},on:{"close":_vm.onTransferDialogClosed,"restart":_vm.signCSR}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }