<template>

    <v-card class="mx-sm-4 mx-0">

        <v-toolbar flat color="primary" dark class="d-none d-sm-flex">
            <v-icon class="mr-2 mt-1">mdi-certificate</v-icon>
            <v-toolbar-title class="title">Certificates</v-toolbar-title>
        </v-toolbar>

        <v-container>

            <v-row no-gutters>

                <v-col cols="12" class="mt-2 mx-2">

                    <p>Sign a CSR using the Authenticate app.</p>

                    <v-form ref="transferDetails">

                        <v-list-item>
                            <v-text-field
                                v-model="commonName"
                                :disabled="creatingCSR"
                                label="Common Name (CN)"
                                :rules="requiredRules"
                            ></v-text-field>
                        </v-list-item>

                        <v-list-item>
                            <v-text-field
                                v-model="organization"
                                :disabled="creatingCSR"
                                label="Organization"
                                :rules="requiredRules"
                            ></v-text-field>
                        </v-list-item>

                    </v-form>

                </v-col>

            </v-row>

            <v-row no-gutters class="mx-2 mb-2">
                <v-spacer></v-spacer>
                <v-btn
                    class="white--text"
                    color="primary"
                    shaped
                    :disabled="creatingCSR"
                    @click="signCSR"
                >
                    <v-progress-circular
                        v-show="creatingCSR"
                        size="20"
                        class="mr-1"
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                    Sign CSR
                </v-btn>
            </v-row>

        </v-container>

        <sign-csr-dialog
            v-if="csrOngoing"
            :common-name="commonName"
            :organization="organization"
            :transaction-status="transactionStatus"
            @close="onTransferDialogClosed"
            @restart="signCSR"
        ></sign-csr-dialog>

    </v-card>

</template>

<script>

import SignCsrDialog from "@/components/SignCsrDialog";
import API from "@/utils/api";

const TRANSACTION_STATUS = {
    CREATED: 0,
    CONFIRMED: 3,
    REJECTED: 5,
    EXPIRED: 6,
}

export default {
    name: "ManageCertificates",

    components: {
        SignCsrDialog

    },

    data: () => ({
        commonName: "demo.ubiqu.com",
        creatingCSR: false,
        csrOngoing: false,
        organization: "Ubiqu",
        requiredRules: [
            v => !!v || "This field is required",
        ],
        timer: null,
        transactionStatus: 0,
    }),

    methods: {

        onTransferDialogClosed() {
            this.csrOngoing = false;
            if (this.timer) {
                clearTimeout(this.timer);
            }
        },

        signCSR() {
            this.transactionStatus = TRANSACTION_STATUS.CREATED;

            this.creatingCSR = true;

            API.signCSR(this.commonName, this.organization)
                .then((response) => {
                    this.creatingCSR = false;
                    let transactionUUID = response.uuid;
                    this.checkCSRStatus(transactionUUID);
                    this.csrOngoing = true;
                })
                .catch((error) => {
                    this.creatingCSR = false;
                    console.log(error);
                })
        },

        async checkCSRStatus(transactionUUID) {
            let pollFunction = async () => {
                let response = await API.checkCSR(transactionUUID);
                this.transactionStatus = response.status;

                switch (this.transactionStatus) {
                    case TRANSACTION_STATUS.CONFIRMED:
                    case TRANSACTION_STATUS.EXPIRED:
                    case TRANSACTION_STATUS.REJECTED:
                        clearTimeout(this.timer);
                        return;
                }

                this.timer = setTimeout(pollFunction, 2000);
            };

            await pollFunction();
        },

    },

    beforeDestroy() {
        if (this.timer != null) {
            clearTimeout(this.timer);
        }
    },

}
</script>

<style scoped>

</style>