<template>

    <v-container class="pt-0">

        <template v-if="!$vuetify.breakpoint.mobile || showForOtherDevice">

            <v-row no-gutters justify="center"
                   :class="{'grey lighten-1': identifyObject === null}"
                   class="colorTransition">

                <v-col cols="12" sm="8">
                    <v-responsive :aspect-ratio="1">

                        <template v-if="identifyObject === null">
                            <!--                        <v-row align="center" class="fill-height" justify="center">-->
                            <!--                            <v-progress-circular v-if="!error" color="primary"-->
                            <!--                                                 indeterminate></v-progress-circular>-->
                            <!--                            <v-icon x-large v-else @click="startIdentifyFlow(true)">mdi-refresh</v-icon>-->
                            <!--                        </v-row>-->
                        </template>

                        <template v-else>
                            <v-img :src='qrcode'
                                   :class="{'opacityStrong' : identifyObject.isLoading}"
                                   transition="fade-transition"
                                   aspect-ratio="1"
                                   width="100%"
                                   contain
                            >
                                <v-row v-if="identifyObject.isLoading"
                                       align="center" class="fill-height" justify="center">
                                    <v-progress-circular color="primary"
                                                         indeterminate></v-progress-circular>
                                </v-row>
                            </v-img>
                        </template>

                    </v-responsive>
                </v-col>
            </v-row>

            <v-row no-gutters class="my-4">

                <v-col v-if="isEKTP" cols="3" align-self="center">
                    <v-img
                        src="../../assets/ektp/ektp-logo.png"
                        style="background-color: white; border-radius: 5px"
                        contain
                        class="mr-8 ml-2"
                    ></v-img>
                </v-col>

                <v-col>

                    <p class="title">{{ header }}</p>

                    <p>1. {{ $t('step1') }} </p>
                    <p>2. {{ $t('step2') }} </p>
                    <p>3. {{ $t('step3') }} </p>

                    <p>
                        {{ $t('no_scanner') }}

                        <v-btn x-small
                               v-if="!showNonce"
                               class="primary"
                               @click="clickedOnShowNonce"
                        >{{ $t('show_nonce') }}</v-btn>
                        <template v-else>
                            {{ $t('enter_nonce') }}
                        </template>
                    </p>

                    <template v-if="showNonce">

                        <p class="text-center text-h6">

                            <kbd
                                class="primary text-center"
                                :class="{'opacityStrong' : identifyObject.isLoading}"
                            >{{ identifyObject.nonce.match(/.{1,3}/g).join(" ") }}</kbd>

                        </p>

                        <template v-if="identifyObject.puk">
                            <p>
                                Optionally enter this PUK:
                            </p>

                            <p class="text-center text-h6 mb-0">

                                <kbd
                                    class="primary text-center"
                                    :class="{'opacityStrong' : identifyObject.isLoading}"
                                >{{ identifyObject.puk_formatted }}</kbd>

                            </p>
                        </template>

                    </template>

                    <template v-if="showForOtherDevice">
                        <v-btn block color="green" dark @click="showForOtherDevice = false">
                            <v-icon class="mr-1">mdi-arrow-left</v-icon>
                            Go back
                        </v-btn>
                    </template>

                </v-col>

            </v-row>

        </template>

        <template v-else>

            <v-row no-gutters>
                <v-col cols="12">

                    <p class="title">{{ header }}</p>

                    <p>1. Click <kbd class="primary">Open App</kbd> to open the Authenticate app.</p>
                    <p>2. Optionally select the profile to login with.</p>
                    <p>Click on <kbd class="green">Show QR</kbd> to scan the QR code with another device.</p>
                </v-col>
            </v-row>

            <v-row no-gutters class="mb-4">
                <v-btn color="green" dark @click="showForOtherDevice = true">
                    <v-icon class="mr-1">mdi-qrcode-scan</v-icon>
                    Show QR
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn color="primary" @click="onOpenAuthenticateClicked">
                    <v-icon class="mr-1">mdi-cellphone</v-icon>
                    Open App
                </v-btn>
            </v-row>

        </template>

    </v-container>

</template>

<script>
import Utils from "@/utils/utils";
import {Environment} from "@/utils/environment";

export default {
    name: "IdentifyView",

    props: {

        appApi: {
            type: String,
            required: false,
        },

        header: {
            type: String,
            required: true
        },

        identifyObject: {
            type: Object,
            required: false
        },

    },

    data: () => ({
        showForOtherDevice: false,
        showNonce: false,
    }),

    computed: {

        isEKTP() {
            return Environment.IS_EKTP;
        },

        qrcode() {
            let blob = Utils.b64ToBlob(this.identifyObject.qrcode);
            return URL.createObjectURL(blob);
        }

    },

    methods: {

        clickedOnShowNonce() {
            this.showNonce = true;
            this.$emit("nonceClicked");
        },

        onOpenAuthenticateClicked() {
            window.location.href = this.appApi;
        },

    },


}
</script>

<style scoped>

    .colorTransition {
        transition: 0.4s linear background-color;
    }

    .opacityStrong {
        opacity: 0.6;
    }

</style>

<i18n>
{
    "en": {
        "step1": "Open the Authenticate app.",
        "step2": "Use the QR code scanner to scan the QR code.",
        "step3": "Optionally select the profile you would like to login with.",
        "no_scanner": "Can't scan QR code?",
        "show_nonce": "Show nonce",
        "enter_nonce": "Enter this nonce in the Authenticate app"
    },
    "in": {
        "step1": "Buka aplikasi iDiGi",
        "step2": "Pilih profil Anda jika Anda terdaCar",
        "step3": "Jika Anda baru pilih DaCar ",
        "no_scanner": "Tidak dapat memindai kode QR?",
        "show_nonce": "Menunjukkan nonce",
        "enter_nonce": "Masukkan nonce ini di aplikasi iDiGi"
    }
}
</i18n>