<template>

    <v-card class="mx-sm-4 mx-0">

        <v-toolbar flat color="primary" dark class="d-none d-sm-flex">
            <v-icon class="mr-2">mdi-bank</v-icon>
            <v-toolbar-title class="text-h6">
                Banking
            </v-toolbar-title>
        </v-toolbar>

        <v-container>

            <v-list two-line class="pt-0">
                <v-list-item class="my-n2">
                    <div class="subtitle-1 font-weight-medium">Bank account</div>
                </v-list-item>

                <v-list-item>
                    <v-list-item-avatar>
                        <v-icon>mdi-account</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>{{ username }}</v-list-item-title>
                        <v-list-item-subtitle>NL00IBAN001111111111</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-avatar>
                        <v-icon>mdi-currency-eur</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>600,00 EUR</v-list-item-title>
                        <v-list-item-subtitle>Balance</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item>
                    <div class="subtitle-1 font-weight-medium">Transfer money</div>
                </v-list-item>

                <v-form ref="transferDetails">

                    <v-list-item>
                        <v-text-field
                            v-model="receiverName"
                            :disabled="creatingTransaction"
                            label="Receiver"
                            :rules="requiredRules"
                        ></v-text-field>
                    </v-list-item>

                    <v-list-item>
                        <v-text-field
                            v-model="receiverNumber"
                            :disabled="creatingTransaction"
                            label="Account Number"
                            :rules="requiredRules"
                        ></v-text-field>
                    </v-list-item>

                    <v-list-item>
                        <v-text-field
                            v-model.number="amount"
                            :disabled="creatingTransaction"
                            label="Amount"
                            prepend-icon="mdi-currency-eur"
                            :rules="requiredRules"
                            @keypress="onlyNumber"
                        ></v-text-field>
                    </v-list-item>

                </v-form>

            </v-list>

            <v-row no-gutters class="mx-2 mb-2">
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    :block="$vuetify.breakpoint.mobile"
                    class="white--text"
                    @click="transferMoney"
                    :disabled="creatingTransaction"
                >
                    <v-progress-circular
                        v-show="creatingTransaction"
                        size="20"
                        class="mr-1"
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                    Transfer</v-btn>
            </v-row>


        </v-container>

        <transfer-money-dialog
            v-if="transferOngoing"
            :account-number="receiverNumber"
            :amount="amount"
            :receiver="receiverName"
            :transactionStatus="transactionStatus"
            @close="onTransferDialogClosed"
            @restart="transferMoney"
        ></transfer-money-dialog>

    </v-card>

</template>

<script>
import TransferMoneyDialog from "@/components/TransferMoneyDialog";
import API from "@/utils/api";
import SessionManager from "@/utils/session";

const TRANSACTION_STATUS = {
    CREATED: 0,
    CONFIRMED: 3,
    REJECTED: 5,
    EXPIRED: 6,
}

export default {
    name: "BankAccount",

    components: {
        TransferMoneyDialog
    },

    data: () => ({
        amount: 20,
        creatingTransaction: false,
        receiverName: "Wilson Noland",
        receiverNumber: "NL00IBAN002222222222",
        requiredRules: [
            v => !!v || "This field is required",
        ],
        timer: null,
        transferOngoing: false,
        transactionStatus: 0,
    }),

    methods: {

        /**
         * Modified version of https://stackoverflow.com/questions/39782176/
         * Allow only numbers and comma character
         */
        onlyNumber(event) {
            let charCode = event.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 44) {
                event.preventDefault();
            } else {
                return true;
            }
        },

        onTransferDialogClosed() {
            this.transferOngoing = false;
            if (this.timer) {
                clearTimeout(this.timer);
            }
        },

        transferMoney() {
            if (!this.$refs.transferDetails.validate()) {
                return;
            }

            this.transactionStatus = TRANSACTION_STATUS.CREATED;

            this.creatingTransaction = true;

            API.pay(this.amount, this.receiverNumber, this.receiverName)
            .then((response) => {
                this.creatingTransaction = false;
                let transactionUUID = response.uuid;
                this.checkTransactionStatus(transactionUUID);
                this.transferOngoing = true;
            })
            .catch((error) => {
                this.creatingTransaction = false;
                console.log(error);
            })
        },

        async checkTransactionStatus(transactionUUID) {
            let pollFunction = async () => {
                let response = await API.checkPayment(transactionUUID);
                this.transactionStatus = response.status;

                switch (this.transactionStatus) {
                    case TRANSACTION_STATUS.CONFIRMED:
                    case TRANSACTION_STATUS.EXPIRED:
                    case TRANSACTION_STATUS.REJECTED:
                        clearTimeout(this.timer);
                        return;
                }

                this.timer = setTimeout(pollFunction, 2000);

            };

            await pollFunction();
        },

    },

    beforeDestroy() {
        if (this.timer != null) {
            clearTimeout(this.timer);
        }
    },

    created() {
        this.username = SessionManager.username;
    }

}
</script>

<style scoped>

</style>