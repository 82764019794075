<template>

    <v-dialog
        v-model="modal"
        persistent
        max-width="500"
    >

        <v-card>

            <v-toolbar color="primary" dark flat>

                <v-toolbar-title class="font-weight-medium">
                    <v-icon>mdi-cellphone-sound</v-icon>
                    Decrypt with Authenticate
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="closeDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Close Dialog</span>
                </v-tooltip>

            </v-toolbar>

            <v-container class="px-4">

                <p class="title text--primary">Decrypt document with app</p>

                <p class="">
                    Open the push message you received on your phone.
                    Click on the message you just received and confirm this with your PIN
                </p>

                <v-alert type="info" dense text color="primary">
                    Didn't receive a push message? Open the app and refresh your screen.
                </v-alert>

                <p>Document that will be decrypted:</p>

                <v-chip
                    class="mb-4 font-weight-medium"
                    color="primary"
                    label
                    outlined
                >
                    <v-icon left>mdi-file-document</v-icon>
                    banking_agreement.pdf
                </v-chip>

                <v-progress-linear
                    indeterminate
                    color="primary"
                ></v-progress-linear>

            </v-container>

        </v-card>



    </v-dialog>

</template>

<script>
export default {
    name: "DecryptFileDialog",

    props: {
    },

    data: () => ({
        modal: true
    }),

    methods: {

        closeDialog() {
            this.$emit("close");
        }

    },

}
</script>

<style scoped>

</style>