import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":"","flat":""}},[_c(VToolbarTitle,{staticClass:"font-weight-medium"},[_c(VIcon,[_vm._v("mdi-cellphone-sound")]),_vm._v(" Sign with Authenticate ")],1),_c(VSpacer),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""},on:{"click":_vm.closeDialog}},on),[_c(VIcon,[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Close Dialog")])])],1),_c(VContainer,{staticClass:"px-4"},[_c('p',{staticClass:"title text--primary"},[_vm._v("Sign CSR with app")]),(_vm.transactionStatus === 0)?[_c('p',{},[_vm._v(" Open the push message you received on your phone. Click on the message you just received and confirm this with your PIN ")]),_c(VAlert,{attrs:{"type":"info","dense":"","text":"","color":"primary"}},[_vm._v(" Didn't receive a push message? Open the app and refresh your screen. ")])]:_vm._e(),_c(VList,{staticClass:"pt-0 mt-n4",attrs:{"two-line":"","subheader":""}},[_c(VSubheader,{staticClass:"font-weight-medium px-0"},[_vm._v("CSR Details")]),_c(VListItem,{staticClass:"px-0"},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.commonName))]),_c(VListItemSubtitle,[_vm._v("Common Name (CN)")])],1)],1),_c(VListItem,{staticClass:"px-0"},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.organization))]),_c(VListItemSubtitle,[_vm._v("Organization")])],1)],1)],1),(_vm.transactionStatus === 0)?_c(VProgressLinear,{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(_vm.transactionStatus === 3)?_c(VAlert,{attrs:{"type":"success","dense":"","text":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VBtn,{staticClass:"my-n2",attrs:{"text":"","color":"green"},on:{"click":_vm.closeDialog}},[_vm._v(" Finish ")])]},proxy:true}],null,false,729608011)},[_vm._v(" CSR signed successfully! ")]):_vm._e(),(_vm.transactionStatus === 5)?_c(VAlert,{attrs:{"type":"error","dense":"","text":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"color":"red","icon":"","small":""},on:{"click":_vm.restartTransaction}},on),[_c(VIcon,[_vm._v("mdi-refresh")])],1)]}}],null,false,4234969885)},[_c('span',[_vm._v("Try again")])])]},proxy:true}],null,false,2995185535)},[_vm._v(" You rejected the CSR. ")]):_vm._e(),(_vm.transactionStatus === 6)?_c(VAlert,{attrs:{"type":"error","dense":"","text":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"color":"red","icon":"","small":""},on:{"click":_vm.restartTransaction}},on),[_c(VIcon,[_vm._v("mdi-refresh")])],1)]}}],null,false,4234969885)},[_c('span',[_vm._v("Try again")])])]},proxy:true}],null,false,2995185535)},[_vm._v(" The CSR expired. ")]):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }