<template>

    <v-window
        v-model="loginWindow"
        :touchless="true"
    >

        <!-- Scan QR code -->
        <v-window-item
            :value="0"
        >
            <identify-view
                :identify-object="identifyObject"
                :app-api="appApi"
                :header="$t('login_header')"
            ></identify-view>
        </v-window-item>

        <!-- Confirm PIN -->
        <v-window-item
            :value="2"
        >

            <v-container>

                <v-row no-gutters>

                    <v-col>

                        <p class="text-h6">{{ $t('confirm_header') }}</p>

                        <p class="subtitle-2">{{ $t('welcome') }} {{ username }}</p>

                        <p>
                            1. {{ $t('step1') }}
                        </p>
                        <p>
                            2. {{ $t('step2') }}
                        </p>

                        <v-alert v-if="!rejected" type="success" dense>{{ $t('success') }}</v-alert>

                        <v-alert v-if="rejected" type="error" dense>
                            {{ $t('error') }}
                            <template v-slot:append>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" icon small @click="restartLogin">
                                            <v-icon>mdi-refresh</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('try_again') }}</span>
                                </v-tooltip>
                            </template>
                        </v-alert>

                    </v-col>

                </v-row>

            </v-container>

        </v-window-item>

    </v-window>

</template>

<script>
import IdentifyView from "@/views/login/IdentifyView";
import API from "@/utils/api";
import SessionManager from "@/utils/session";
import {Environment} from "@/utils/environment";

const LOGIN_STATUS = {
    UNIDENTIFIED: 0,
    IDENTIFIED: 1,
    AUTHENTICATED: 3,
    REJECTED: 5,
    EXPIRED: 6
}

const LOGIN_WINDOWS = {
    SCAN_QRCODE: 0,
    CHOOSE_USER: 1,
    CONFIRM_PIN: 2,
}

const UPDATE_IDENTIFY_CODE_MINUTES = 2;

export default {
    name: "LoginView",

    components: {
        IdentifyView
    },

    data: () => ({
        appApi: "",
        error: false,
        identifyObject: null,
        identifyTimer: null,
        loginWindow: 0,
        rejected: false,
        statusTimer: null,
        username: "",
    }),

    methods: {

        startIdentifyFlow(removeError = false) {
            if (removeError) {
                this.error = false;
            }

            this.loginWindow = LOGIN_WINDOWS.SCAN_QRCODE

            this.updateIdentificationCode().then(() => this.checkUntilLoginFinished());
        },

        async updateIdentificationCode() {
            // If there is already an identification code shown, add loading state.
            if (this.identifyObject !== null) {
                this.identifyObject.isLoading = true;
            }

            let response = await API.login();

            this.identifyObject = {
                qrcode: response.identification_qr,
                nonce: response.nonce,
                isLoading: false,
            };

            this.appApi = `ubiqu://?idp=${Environment.IDP}&version=1&method=identify&nonce=${this.identifyObject.nonce}&use`;
        },

        async checkUntilLoginFinished() {
            this.identifyTimer = setInterval(this.updateIdentificationCode, 60 * 1000 * UPDATE_IDENTIFY_CODE_MINUTES);

            let verifyStatusOfLoginProcess = async () => {
                let response = await API.getSession();
                let status = response.login_session.status;

                switch (status) {
                    case LOGIN_STATUS.UNIDENTIFIED:
                        break;
                    case LOGIN_STATUS.IDENTIFIED:
                        this.loginWindow = LOGIN_WINDOWS.CONFIRM_PIN;
                        if (response.user) {
                            this.username = response.user.username;
                            SessionManager.username = response.user.username;
                        }
                        break;
                    case LOGIN_STATUS.AUTHENTICATED:
                        if (response.logged_in) {
                            this.completeLoginProcess();
                            return;
                        }
                        break;
                    case LOGIN_STATUS.REJECTED:
                        clearTimeout(this.statusTimer);
                        clearInterval(this.identifyTimer);
                        this.rejected = true;
                        return;
                    default:
                        console.log(status);
                }

                this.statusTimer = setTimeout(verifyStatusOfLoginProcess, 2000);
            }

            await verifyStatusOfLoginProcess();
        },

        completeLoginProcess() {
            clearTimeout(this.statusTimer);
            clearInterval(this.identifyTimer);
            SessionManager.authenticated = true;
            this.$router.push({name: 'home'});
        },

        restartLogin() {
            this.rejected = false;
            this.startIdentifyFlow();
        },

    },

    beforeDestroy() {
        if (this.statusTimer) {
            clearTimeout(this.statusTimer);
        }

        if (this.identifyTimer) {
            clearInterval(this.identifyTimer);
        }
    },

    created() {
        this.startIdentifyFlow();
    }

}
</script>

<style scoped>

</style>

<i18n>
{
    "en": {
        "login_header": "How to login into ubiqu bank",
        "confirm_header": "Confirm with PIN",
        "welcome": "Welcome",
        "step1": "Open the Authenticate app and click on the push message you just received.",
        "step2": "Verify the push message and confirm with your PIN.",
        "success": "After confirming your PIN you will be logged in",
        "error": "You rejected the login request",
        "try_again": "Try again"

    },
    "in": {
        "login_header": "Cara masuk menggunakan iDiGi",
        "confirm_header": "Konfirmasi dengan PIN",
        "welcome": "Selamat datang",
        "step1": "Buka aplikasi iDiGi dan klik pesan push yang baru saja Anda terima.",
        "step2": "Verifikasi pesan push dan konfirmasi dengan PIN Anda.",
        "success": "Setelah mengkonfirmasi PIN Anda, Anda akan masuk",
        "error": "Anda menolak permintaan login",
        "try_again": "Coba lagi"
    }
}
</i18n>