<template>

    <v-container>

        <v-window
            v-model="registrationWindow"
        >

            <v-window-item
                :value="0"
            >
                <v-row no-gutters>

                    <v-col>

                        <p class="title">{{ $t('header') }}</p>

                        <p>{{ $t('subheader') }}</p>

                        <v-text-field
                            v-model="name"
                            :disabled="verifyingName"
                            :label="$t('username')"
                            outlined
                            @keydown.enter="onRegisterPressed"
                        >
                            <template v-slot:append>
                                <v-progress-circular
                                    v-show="verifyingName"
                                    size="20"
                                    indeterminate
                                    color="primary"
                                ></v-progress-circular>
                            </template>
                        </v-text-field>

                    </v-col>

                </v-row>

                <v-row no-gutters class="mt-0">

                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        class=""
                        :disabled="name.length < 1 || verifyingName"
                        @click="onRegisterPressed"
                    >
                        {{ $t('register') }}
                    </v-btn>

                </v-row>

            </v-window-item>

            <v-window-item
                :value="1"
            >

                <identify-view
                    :identify-object="registrationObject"
                    :app-api="appApi"
                    :header="$t('register_header')"
                    @nonceClicked="userClickedOnNonce = true"
                ></identify-view>

            </v-window-item>

            <v-window-item
                :value="2"
            >

                <v-container>

                    <v-row no-gutters>

                        <v-col>

                            <template v-if="userClickedOnNonce && registrationObject">
                                <p class="title">{{ $t('enter_puk_header') }}</p>

                                <p>
                                    {{ $t('enter_puk_explanation') }}
                                </p>

                                <p class="text-center text-h6">

                                    <kbd
                                        class="primary text-center"
                                        :class="{'opacityStrong' : registrationObject.isLoading}"
                                    >{{ registrationObject.puk_formatted }}</kbd>

                                </p>
                            </template>

                            <p class="title">{{ $t('choose_pin_header') }}</p>

                            <p>
                                {{ $t('choose_pin_explanation') }}
                            </p>

                        </v-col>

                    </v-row>

                    <v-alert type="success" dense>
                        {{ $t('pin_confirmation') }}
                    </v-alert>

                </v-container>

            </v-window-item>

        </v-window>

    </v-container>

</template>

<script>
import IdentifyView from "@/views/login/IdentifyView";
import API from "@/utils/api";
import SessionManager from "@/utils/session";
import {Environment} from "@/utils/environment";

const REGISTRATION_WINDOW = {
    CHOOSE_NAME: 0,
    ENTER_QRCODE: 1,
    CHOOSE_PIN: 2,
};

const REGISTRATION_STATUS = {
    CREATED: 0,
    ACTIVE: 1,
    REGISTERED: 2,
    UNLOCKED: 3,
    LOCKED: 4,
    DESTROYED: 5,
    REVOKED: 6,
    COMPROMISED: 7,
};

export default {
    name: "RegisterView",

    components: {
        IdentifyView
    },

    props: {},

    data: () => ({
        appApi: "",
        name: "",
        timer: null,
        registrationWindow: 0,
        registrationObject: null,
        userClickedOnNonce: false,
        verifyingName: false,
    }),

    methods: {

        /**
         * Checks if the username is valid and will start the creation process
         * @returns {Promise<void>}
         */
        async onRegisterPressed() {
            if (this.name.length === 0) return;

            this.verifyingName = true;

            let response;
            try {
                response = await API.register(this.name);
            } catch (e) {
                console.error(e);
                this.verifyingName = false;
                return;
            }
            let subscriptionData = response.token.subscription_data;

            this.registrationObject = {
                qrcode: subscriptionData.subscription_qr,
                nonce: subscriptionData.subscription_nonce,
                isLoading: false,
                puk: subscriptionData.puk,
                puk_formatted: subscriptionData.puk_formatted,
            };

            this.appApi =
                "ubiqu://?idp=" + Environment.IDP +
                "&version=1&method=subscribe&nonce=" + this.registrationObject.nonce +
                "&puk=" + this.registrationObject.puk +
                "&profilename=" + encodeURIComponent(this.name);

            this.verifyingName = false;
            this.registrationWindow = REGISTRATION_WINDOW.ENTER_QRCODE;

            await this.checkUntilUserRegistered();
        },

        async checkUntilUserRegistered() {
            let verifyStatusFunction = async () => {
                let status = await this.checkRegistrationStatus();

                switch (status) {
                    case REGISTRATION_STATUS.ACTIVE:
                        this.registrationWindow = REGISTRATION_WINDOW.CHOOSE_PIN;
                        break;
                    case REGISTRATION_STATUS.REGISTERED:
                        this.registrationWindow = REGISTRATION_WINDOW.CHOOSE_PIN;
                        break;
                    case REGISTRATION_STATUS.UNLOCKED:
                        clearTimeout(this.timer);
                        SessionManager.username = this.name
                        SessionManager.authenticated = true;
                        await this.$router.push({name: 'home'});
                        break;
                }

                this.timer = setTimeout(verifyStatusFunction, 2000);
            }

            await verifyStatusFunction();
        },

        /**
         * Checks the user's registration status and will update UI corresponding to status
         * @returns {Promise<void>}
         */
        async checkRegistrationStatus() {
            let response;
            try {
                response = await API.getSession();
            } catch (e) {
                console.error(e);
                this.verifyingName = false;
            }

            return response.user.token.status;
        },

    },

    beforeDestroy() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }


}
</script>

<style scoped>

.colorTransition {
    transition: 0.4s linear background-color;
}

.opacityStrong {
    opacity: 0.6;
}

</style>

<i18n>
{
    "en": {
        "header": "How to register at ubiqu bank",
        "register_header": "Register with the Authenticate app",
        "subheader": "Choose any username you like.",
        "username": "Username",
        "register": "Register",
        "enter_puk_header": "Enter PUK (optional)",
        "enter_puk_explanation": "If you have entered the nonce manually you need to enter the following PUK in the Authenticate app. If the app is asking you to choose a PIN you can skip this step.",
        "pin_confirmation": "After confirming your PIN your registration is finished!",
        "choose_pin_header": "Choose a PIN",
        "choose_pin_explanation": "In order to use the Authenticate app you need to set a 5 digit PIN code. Enter and confirm the PIN in order to successfully finish the registration."
    },
    "in": {
        "header": "Cara mendaftar di Rumah Sakit Medistra",
        "register_header": "Daftar dengan aplikasi iDiGi",
        "subheader": "Pilih nama pengguna Anda",
        "username": "Nama pengguna",
        "register": "Daftar",
        "enter_puk_header": "Masukkan PUK (opsional)",
        "enter_puk_explanation": "Jika Anda telah memasukkan nonce secara manual, Anda harus memasukkan PUK berikut di aplikasi iDiGi. Jika aplikasi meminta Anda untuk memilih PIN, Anda dapat melewati langkah ini.",
        "pin_confirmation": "Setelah mengkonfirmasi PIN Anda, pendaftaran Anda selesai!",
        "choose_pin_header": "Pilih PIN",
        "choose_pin_explanation": "Untuk menggunakan aplikasi iDiGi, Anda perlu mengatur kode PIN 5 digit. Masukkan dan konfirmasi PIN agar berhasil menyelesaikan pendaftaran."

    }
}
</i18n>