import axios from 'axios';
import {Environment} from "@/utils/environment";
import SessionManager from "@/utils/session";
import BindingManager from "@/utils/binding";

const APIHandler = {

    communicator: Object,

    logOutRoutine: Function,

    init() {
        let API_URL = Environment.API_BASE_URL;

        this.communicator = axios.create({
            baseURL: API_URL,
            timeout: 10000,
            withCredentials: true,
        });

    },

    handleError(error) {
        switch (error.response.status) {
            case 500:
                console.error("Internal Server Error!")
                return;
        }

        if (error.response.data.error) {

            switch (error.response.data.error.code) {
                case API.ERRORS.NOT_LOGGED_IN:
                    this.logOutRoutine();
                    break;
                case API.ERRORS.INVALID_CSRF_TOKEN:
                    BindingManager.start().then();
                    break;
            }

        }
    },

    async get(endpoint, queryParams = null) {
        let header = {"X-CSRF-TOKEN": SessionManager.getCSRFToken()};

        try {
            const response = await this.communicator.get(endpoint, {params: queryParams, headers: header});

            if (response.status < 300) {
                SessionManager.setCSRFToken(response.data["csrf"]);
            }

            return response.data.result;
        } catch (error) {
            this.handleError(error);
            throw error;
        }
    },

    async post(endpoint, data) {
        let header = {"X-CSRF-TOKEN": SessionManager.getCSRFToken()};

        try {
            const response = await this.communicator.post(endpoint, data, {headers: header});

            if (response.status < 300) {
                SessionManager.setCSRFToken(response.data["csrf"]);
            }

            return response.data.result;
        } catch (error) {
            this.handleError(error);
            throw error;
        }
    },

};

const API = {

    init(routine) {
        APIHandler.logOutRoutine = routine;
    },

    ERRORS: {
        NOT_LOGGED_IN: 7,
        INVALID_CSRF_TOKEN: 10,
    },

    async register(username) {
        let data = {username: username};

        return await APIHandler.post("register/", data);
    },

    async getSession() {
        return await APIHandler.get("session/");
    },

    async login() {
        return await APIHandler.post("login/", {});
    },

    async logout() {
        return await APIHandler.post("logout/", {});
    },

    async pay(amount, account_number, receiver) {
        let data = {
            amount: amount,
            account_nr: account_number,
            receiver: receiver
        }

        return await APIHandler.post("pay/", data);
    },

    async checkPayment(transactionUUID) {
        return await APIHandler.get(`pay/${transactionUUID}/`);
    },

    async signCSR(commonName, organization) {
        let data = {
            common_name: commonName,
            organization: organization
        };

        return await APIHandler.post("sign_csr/", data);
    },

    async checkCSR(transactionUUID) {
        return await APIHandler.get(`sign_csr/${transactionUUID}/`);
    },

}

APIHandler.init();

export default API;