import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c(VAppBar,{attrs:{"app":"","clipped-left":"","color":"primary","dark":""}},[(_vm.isEKTP)?_c(VImg,{staticClass:"mr-4",staticStyle:{"background-color":"white","border-radius":"5px"},attrs:{"src":require("./assets/ektp/ektp-logo.png"),"max-height":"30","max-width":"30","contain":""}}):_vm._e(),(!_vm.isEKTP)?_c(VImg,{staticClass:"mr-4",attrs:{"src":require("./assets/ubiqu_white.svg"),"max-height":"30","max-width":"30","contain":""}}):_vm._e(),_c(VToolbarTitle,{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.applicationTitle)+" ")]),_c(VSpacer),(!_vm.isLoginView)?_c(VBtn,{staticClass:"white--text",attrs:{"text":""},on:{"click":_vm.onLogoutClicked}},[_vm._v(_vm._s(_vm.$t('logout')))]):_vm._e()],1),_c(VMain,{staticStyle:{"background-color":"#eeeeee"}},[_c('router-view',{staticStyle:{"height":"100%"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }