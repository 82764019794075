import {StorageManager} from "@/utils/storage";
import API from "@/utils/api";

const CONFIG = {
    CSRF_ATTRIBUTE: "x-csrf-token",
}

const SessionErrors = Object.freeze({
    X_CSRF_TOKEN_INVALID: "X-CSRF-Token is invalid. Start binding process.",
    SESSION_INVALID: "Active session is invalid. Start binding process to retrieve a new one.",
})

const SessionManager = {

    ERRORS: SessionErrors,

    authenticated: false,

    username: "",

    async isAuthenticated() {
        if (this.authenticated) {
            return true;
        }

        if (this.getCSRFToken() == null) {
            throw new Error(SessionErrors.X_CSRF_TOKEN_INVALID);
        }

        let response;

        try {
            response = await API.getSession();
        } catch (e) {
            console.log(e);
            throw new Error(SessionErrors.X_CSRF_TOKEN_INVALID);
        }

        this.authenticated = response.logged_in;

        if (!this.authenticated) {
            return false;
        }

        this.username = response.user.username;

        return true;
    },

    async logout () {
        await API.logout();
        this.authenticated = false;
    },

    getCSRFToken() {
        let csrfAttribute;

        try {
            csrfAttribute = StorageManager.get(CONFIG.CSRF_ATTRIBUTE);
        } catch (e) {
            return null;
        }

        if (Object.prototype.hasOwnProperty.call(!csrfAttribute, "token")) {
            return null;
        }

        return csrfAttribute.token;
    },

    setCSRFToken(value) {
        let tokenObject = {
            token: value
        };

        StorageManager.set(CONFIG.CSRF_ATTRIBUTE, tokenObject);
    },

}

export default SessionManager;